body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(74, 186, 224);
  background: linear-gradient(
    180deg,
    rgba(74, 186, 224, 1) 0%,
    rgba(74, 186, 224, 1) 7%,
    rgba(67, 156, 196, 1) 26%,
    rgba(60, 129, 169, 1) 47%,
    rgba(51, 106, 147, 1) 66%,
    rgba(44, 82, 125, 1) 82%,
    rgba(26, 28, 84, 1) 100%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#supermine-io {
  /* margin: 0 auto; */
  min-height: 100vh;
  /* width: 100vw; */
  /* max-width: 90rem; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
